import MaterialTable from "material-table"
import React, {useEffect, useState} from "react"
import PulseCombinedAlarmModal from "../modals/PulseCombinedAlarmModal"
import DeleteModal from "../../../../components/modals/DeleteModal"
import {tableIcons} from "../../../../components/tables/tableIcons"
import {renameType} from "../../utils/enumMapping"
import {ACTION_TYPE, UNIT_ALL_ID, usePulseCombinedAlarms} from "../../../../utils/api/alarms";


export default function PulseCombinedAlarmTable({id, actionType}) {
    const [data, setData] = useState([])
    const [modalAdd, setModalAdd] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const getPulseCombinedAlarms = usePulseCombinedAlarms(id,undefined,actionType)

    useEffect(()=>{
        if(getPulseCombinedAlarms.isSuccess) {
            setData(getPulseCombinedAlarms.data?.data.results)
        }
        // eslint-disable-next-line
    }, [getPulseCombinedAlarms.data])


    useEffect(
        (_) => {
            getPulseCombinedAlarms.refetch()
        },
        // eslint-disable-next-line
        [modalAdd, modalDelete]
    )

    const deleteRow = (event, rowData) => {
        setModalDelete(rowData)
    }

    return (
        <>
            {modalAdd && (
                <PulseCombinedAlarmModal
                    open={modalAdd}
                    handleClose={() => {
                        setModalAdd(false)
                        setEditModal(false)
                    }}
                    unit={id}
                    edit={editModal}
                    actionType={actionType}
                />
            )}
            <DeleteModal
                open={!!modalDelete}
                handleClose={() => {
                    setModalDelete(false)
                }}
                url={`/pulse_combined_alarms/${modalDelete.id}/`}
                deleteText="DELETE COMBINED PULSE ALARM"
                header={`Delete combined pulse alarm ${modalDelete.name}?`}
                alertText={"Combined pulse alarm was deleted"}
            />

            <MaterialTable
                icons={tableIcons}
                columns={[
                    {title: "Name", field: "name"},
                    {
                        title: "Type",
                        field: "type",
                        render: (rowData) => renameType(rowData.type),
                    },
                    {
                        title: "Trigger type",
                        field: "trigger_type",
                        render: (rowData) => renameType(rowData.trigger_type),
                    },
                    {title: "Main expression", field: "expression1_name"},
                    {title: "Secondary expression", field: "expression2_name"},
                    {title: "Expression", field: "custom_expression"},
                    {
                        title: "Main expression value range",
                        field: "min_expression_1_difference",
                    },
                    {
                        title: "Silenced",
                        field: "enabled",
                        render: (rowData) => rowData.enabled ? 'no' : 'yes'
                    },
                    {title: "Trigger value", field: "value"},
                    {
                        title: "E-mails",
                        hidden: actionType !== ACTION_TYPE.EMA,
                        render: (rowData) =>
                            rowData.email_action_detail?.emails
                                .reduce((acc, item) => acc + ", " + item, "")
                                .substring(1),
                    },
                    {
                        title: "Reactivate alarms",
                        hidden: actionType !== ACTION_TYPE.ENA,
                        render: (rowData) =>
                            rowData.enable_alarm_actions_details
                                .reduce((acc, item) => acc + ", " + item.alarm_name, "")
                                .substring(1),
                    },
                ]}
                data={data}
                title="Combined pulse alarm"
                isLoading={getPulseCombinedAlarms.isLoading}
                actions={[
                    {
                        icon: tableIcons.Add,
                        tooltip: "Add combined pulse alarm",
                        isFreeAction: true,
                        disabled: id === UNIT_ALL_ID,
                        onClick: () => setModalAdd(true),
                    },
                    {
                        icon: tableIcons.Delete,
                        tooltip: "Delete alarm",
                        onClick: deleteRow,
                    },
                    {
                        icon: tableIcons.Edit,
                        tooltip: "Edit alarm",
                        disabled: id === UNIT_ALL_ID,
                        onClick: (event, rowData) => {
                            setEditModal(rowData)
                            setModalAdd(true)
                        },
                    },
                ]}
                options={{
                    pageSize: 10,
                    actionsColumnIndex: -1,
                    search: false,
                }}
            />
        </>
    )
}
