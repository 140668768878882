import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, Input,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import {makeStyles} from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {setAlert} from "../../../../context/alert"
import {
    alarmTriggerType,
    alarmType,
    renameType,
} from "../../utils/enumMapping"
import {
    ACTION_TYPE,
    ALARM_TYPES, useAlarmContentType,
    useAlarmEmails, useAllAlarms,
} from "../../../../utils/api/alarms";
import {EXPRESSION, useExpressions} from "../../../../utils/api/expressions";
import {listAlarms} from "./alarmsutils";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
        height: "25rem",
        width: "30rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
    },
    grid: {
        marginTop: "1rem",
    },

    formControl: {
        marginTop: 10,
        marginRight: 10,
    },
    expression: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
    },
}))

export default function AlarmModal({actionType, ...props}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [name, setName] = useState(props.edit ? props.edit.name : "")
    // eslint-disable-next-line
    const [type, setType] = useState(props.edit ? props.edit.type : "")
    const [triggerType, setTriggerType] = useState(
        props.edit.trigger_type ? props.edit.trigger_type : ""
    )
    const [expression, setExpression] = useState("")
    const [value, setValue] = useState(props.edit ? props.edit.value : "")
    const [emails, setEmails] = useState(props.edit ? props.edit.email_action_detail?.emails : "")
    const [expressions, setExpressions] = useState([])

    const [alarms, setAlarms] = useState(props.edit ? props.edit.enable_alarm_actions_details.map(i=>({...i, name: i.alarm_name, id: i.alarm_id, contentTypeId: i.alarm_content_type})) : [])
    const useAlarmRequest = useAlarmEmails()
    const allAlarms = useAllAlarms(props.unit)
    const useContentType = useAlarmContentType()


    const expressionsRequest = useExpressions(props.unit, (
        props.digital
        ? EXPRESSION.DIGITAL
        : EXPRESSION.ANALOG))

    useEffect(()=>{
        if(expressionsRequest.isSuccess){
            let data = expressionsRequest.data.data
            setExpressions(data.results)
            if (props.edit) {
                setExpression(
                    data.results.find((x) => x.id === props.edit.expression)
                )
            }
        }
        // eslint-disable-next-line
    },[expressionsRequest.data])


    const handleSubmit = (e) => {
        e.preventDefault()

        useAlarmRequest.mutateAsync({
                alarmType: props.digital ? ALARM_TYPES.digitalalarm :  ALARM_TYPES.analogalarm,
                alarmData: {
                    name,
                    type,
                    value,
                    trigger_type: triggerType,
                    expression: expression.id,
                },
                emailActionData: { emails },
                edit: props.edit,
                enableAlarms: alarms.map(alarm => ({
                    ...alarm,
                    contentTypeId: alarm.alarm_content_type || useContentType.data.data[alarm.alarmType.type]
                })),
                actionType: actionType
        })
            .then(()=>{
                if (props.edit) {
                    dispatch(
                        setAlert({
                            status: "success",
                            message: "Alarm was edited.",
                        })
                    )
                } else {
                    dispatch(
                        setAlert({
                            status: "success",
                            message: "Alarm was created.",
                        })
                    )
                }
                props.handleClose()

            })
            .catch(()=>{
                dispatch(
                    setAlert({
                        severity: "error",
                        message: "Something went wrong.",
                    })
                )
            })
            .finally(()=>{
                props.handleClose()
            })

    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">
                {(props.edit ? "Edit " : "Add ") +
                    (props.digital ? "digital" : "analog") +
                    " alarm"}
            </DialogTitle>
            <DialogContent dividers={true}>
                <form
                    className={classes.root}
                    id="add_expression_form"
                    onSubmit={handleSubmit}
                >
                    <TextField
                        label="Name:"
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        className={classes.formControl}
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />

                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Expressions</InputLabel>
                        <Select
                            value={expression}
                            onChange={(e) => {
                                setExpression(e.target.value)
                                if (props.digital) {
                                    setValue(e.target.value.true_value)
                                }
                            }}
                            renderValue={(selected) => selected.name}
                        >
                            {expressions.map((item, index) => (
                                <MenuItem key={`expr_${index}`} value={item}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {!props.digital && (
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Select
                                onChange={(e) => {
                                    setType(e.target.value)
                                }}
                                value={type}
                                renderValue={(selected) => renameType(selected)}
                            >
                                {Object.entries(alarmType).map(
                                    ([key, item]) => {
                                        return (
                                            <MenuItem key={key} value={key}>
                                                {item}
                                            </MenuItem>
                                        )
                                    }
                                )}
                            </Select>
                        </FormControl>
                    )}

                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Trigger type</InputLabel>
                        <Select
                            onChange={(e) => {
                                setTriggerType(e.target.value)
                            }}
                            value={triggerType}
                            renderValue={(selected) => renameType(selected)}
                        >
                            {Object.entries(alarmTriggerType).map(
                                ([key, item]) => {
                                    return (
                                        <MenuItem key={key} value={key}>
                                            {item}
                                        </MenuItem>
                                    )
                                }
                            )}
                        </Select>
                    </FormControl>

                    {!props.digital ? (
                        <>
                            <TextField
                                label="Value:"
                                variant="standard"
                                required
                                fullWidth
                                size="small"
                                className={classes.formControl}
                                onChange={(e) => {
                                    setValue(e.target.value)
                                }}
                                helperText={"Must be numeric value!"}
                                value={value}
                            />
                        </>
                    ) : (
                        <FormControl
                            className={classes.formControl}
                            fullWidth
                            disabled={!expression}
                        >
                            <InputLabel>Value</InputLabel>
                            <Select
                                onChange={(e) => {
                                    setValue(e.target.value)
                                }}
                                value={value}
                            >
                                <MenuItem value={expression.true_value}>
                                    {expression.true_value}
                                </MenuItem>
                                <MenuItem value={expression.false_value}>
                                    {expression.false_value}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    {
                        actionType === ACTION_TYPE.EMA &&
                        <TextField
                            label="Notify:"
                            placeholder={"info@gmail.com, office@test.com, ..."}
                            variant="standard"
                            fullWidth
                            size="small"
                            className={classes.formControl}
                            onChange={(e) => setEmails(e.target.value)}
                            value={emails}
                        />
                    }
                    { actionType === ACTION_TYPE.ENA &&
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="demo-mutiple-chip-label">Reactivate alarms</InputLabel>
                        <Select
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            value={alarms}
                            onChange={
                                (e)=>{
                                    let lastItem = e.target.value[e.target.value.length-1]
                                    let wasInAlarms = alarms.find(i=>i.name === lastItem.name)
                                    if (wasInAlarms){
                                        setAlarms(alarms => alarms.filter(i => i.name !== lastItem.name))
                                    }
                                    else{
                                        setAlarms(e.target.value)
                                    }

                                }
                            }
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => {
                                return selected.map(i=> i.name).join(", ")
                            }}
                        >
                            {
                                allAlarms.every(i=>i.isSuccess) && listAlarms(allAlarms, alarms)
                            }
                        </Select>
                    </FormControl>
                    }
                </form>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    justify="flex-end"
                    spacing={2}
                    className={classes.grid}
                >
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={props.handleClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        {useAlarmRequest.isLoading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                type="submit"
                                form="add_expression_form"
                                variant="contained"
                                color="primary"
                            >
                                {(props.edit ? "Edit " : "Add ") +
                                    (props.digital ? "digital " : "analog") +
                                    " alarm"}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
