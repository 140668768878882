import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    Tooltip,
    Typography,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import {makeStyles} from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import PropTypes from "prop-types"
import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {setAlert} from "../../../../context/alert"
import {alarmTriggerType, renameType} from "../../utils/enumMapping"
import {ALARM_TYPES, useAlarmEmails} from "../../../../utils/api/alarms";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
        height: "30rem",
        width: "30rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
    },
    grid: {
        marginTop: "1rem",
    },

    formControl: {
        marginTop: 10,
        marginRight: 10,
    },
    interval: {
        padding: 10,
    },
}))

function ValueLabelComponent(props) {
    const {children, open, value} = props

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    )
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
}

export function secondsToString(minutes) {
    var value = minutes

    var units = {
        day: 24 * 60,
        hour: 60,
        minute: 1,
    }

    var result = []

    for (var name in units) {
        var p = Math.floor(value / units[name])
        if (p === 1) result.push(" " + p + " " + name)
        if (p >= 2) result.push(" " + p + " " + name + "s")
        value %= units[name]
    }

    return result
}

export function MissingAlarmModal(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [name, setName] = useState(props.edit ? props.edit.name : "")
    const [interval, setInterval] = useState(
        props.edit ? props.edit.interval : 0
    )
    const [triggerType, setTriggerType] = useState(
        props.edit.trigger_type ? props.edit.trigger_type : ""
    )
    const [minutes, setMinutes] = useState(0)
    const [hours, setHours] = useState(0)
    const [days, setDays] = useState(0)
    const [emails, setEmails] = useState(props.edit ? props.edit.email_action_detail?.emails : "")

    const useAlarmRequest = useAlarmEmails()


    useEffect(() => {
        setInterval(minutes + hours + days)
    }, [minutes, hours, days])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (interval === 0) {
            dispatch(
                setAlert({severity: "error", message: "Interval is not set!"})
            )
            return
        }

        useAlarmRequest.mutateAsync({
            alarmType: ALARM_TYPES.missingalarm,
            alarmData: {
                name,
                interval,
                trigger_type: triggerType,
                unit: props.unit,
            },
            emailActionData: { emails },
            edit: props.edit
        })
            .then(()=>{
                if (props.edit) {
                    dispatch(
                        setAlert({
                            status: "success",
                            message: "Alarm was edited.",
                        })
                    )
                } else {
                    dispatch(
                        setAlert({
                            status: "success",
                            message: "Alarm was created.",
                        })
                    )
                }
                props.handleClose()

            })
            .catch(()=>{
                dispatch(
                    setAlert({
                        severity: "error",
                        message: "Something went wrong.",
                    })
                )
            })
            .finally(()=>{
                props.handleClose()
            })
    }


    useEffect(() => {
        setInterval(props.edit ? props.edit.interval : 0)
        setMinutes(interval % 60)
        setHours(Math.floor((interval % 1440) / 60) * 60)
        setDays(Math.floor(interval / 1440) * 1440)
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">
                    {(props.edit ? "Edit " : "Add ") + " missing alarm"}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <form
                        className={classes.root}
                        id="add_expression_form"
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            label="Name:"
                            variant="standard"
                            required
                            fullWidth
                            size="small"
                            className={classes.formControl}
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel>Trigger type</InputLabel>
                            <Select
                                onChange={(e) => {
                                    setTriggerType(e.target.value)
                                }}
                                value={triggerType}
                                renderValue={(selected) => renameType(selected)}
                            >
                                {Object.entries(alarmTriggerType).map(
                                    ([key, item]) => {
                                        return (
                                            <MenuItem key={key} value={key}>
                                                {item}
                                            </MenuItem>
                                        )
                                    }
                                )}
                            </Select>
                        </FormControl>
                        <Typography id="discrete-slider-always" gutterBottom>
                            Alarm interval:
                            <Box fontWeight="fontWeightBold" display="inline">
                                {secondsToString(interval)}
                            </Box>
                        </Typography>
                        <div className={classes.interval}>
                            <Slider
                                className={{padding: 10}}
                                getAriaValueText={(e) => `${e}min`}
                                aria-labelledby="discrete-slider-always"
                                step={1}
                                marks={[0, 5, 30, 60].map((item) => ({
                                    value: item,
                                    label: secondsToString(item),
                                }))}
                                valueLabelDisplay="auto"
                                min={0}
                                max={60}
                                value={interval % 60}
                                onChange={(event, newValue) => {
                                    setMinutes(newValue)
                                }}
                                ValueLabelComponent={ValueLabelComponent}
                                valueLabelFormat={(x) => {
                                    return secondsToString(x)
                                }}
                            />
                        </div>
                        <div className={classes.interval}>
                            <Slider
                                className={{padding: 10}}
                                getAriaValueText={(e) => `${e}min`}
                                aria-labelledby="discrete-slider-always"
                                step={60}
                                marks={[0, 720, 1440].map((item) => ({
                                    value: item,
                                    label: secondsToString(item),
                                }))}
                                valueLabelDisplay="auto"
                                min={0}
                                max={1440}
                                value={Math.floor((interval % 1440) / 60) * 60}
                                onChange={(event, newValue) => {
                                    setHours(newValue)
                                }}
                                ValueLabelComponent={ValueLabelComponent}
                                valueLabelFormat={(x) => {
                                    return secondsToString(x)
                                }}
                            />
                        </div>
                        <div className={classes.interval}>
                            <Slider
                                className={{padding: 10}}
                                getAriaValueText={(e) => `${e}min`}
                                aria-labelledby="discrete-slider-always"
                                step={1440}
                                marks={[1440, 20160].map((item) => ({
                                    value: item,
                                    label: secondsToString(item),
                                }))}
                                valueLabelDisplay="auto"
                                min={0}
                                max={20160}
                                value={Math.floor(interval / 1440) * 1440}
                                onChange={(event, newValue) => {
                                    setDays(newValue)
                                }}
                                ValueLabelComponent={ValueLabelComponent}
                                valueLabelFormat={(x) => {
                                    return secondsToString(x)
                                }}
                            />
                        </div>
                        <TextField
                            label="Notify:"
                            placeholder={"info@gmail.com, office@test.com, ..."}
                            variant="standard"
                            fullWidth
                            size="small"
                            className={classes.formControl}
                            value={emails}
                            onChange={(e) => {
                                setEmails(e.target.value)
                            }}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        justify="flex-end"
                        spacing={2}
                        className={classes.grid}
                    >
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={props.handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            {useAlarmRequest.isLoading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    type="submit"
                                    form="add_expression_form"
                                    variant="contained"
                                    color="primary"
                                >
                                    {(props.edit ? "Edit " : "Add ") +
                                        " missing alarm"}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}
