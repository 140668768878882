import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    ACTION_TYPE,
    ALARM_TYPES,
    useAlarmContentType,
    useAlarmEmails,
    useAllAlarms
} from "../../../../utils/api/alarms";
import {setAlert} from "../../../../context/alert";
import {EXPRESSION, useExpressions} from "../../../../utils/api/expressions";
import {
    Box, CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, Input,
    InputLabel,
    MenuItem,
    Select, Slider, Tooltip,
    Typography
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {alarmTriggerType, alarmType, renameType} from "../../utils/enumMapping";
import {listAlarms} from "./alarmsutils";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {secondsToString} from "./PulseAlarmModal";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
        height: "25rem",
        width: "30rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
    },
    grid: {
        marginTop: "1rem",
    },

    formControl: {
        marginTop: 10,
        marginRight: 10,
    },
    interval: {
        padding: 10,
    },
}))

function ValueLabelComponent(props) {
    const {children, open, value} = props

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    )
}

export function AnalogIntervalAlarmModal({actionType, ...props}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [name, setName] = useState(props.edit ? props.edit.name : "")
    const [expression, setExpression] = useState(
        props.edit
            ? {
                id: props.edit.expression,
                name: props.edit.expression_name,
            }
            : ""
    )
    const [value, setValue] = useState(props.edit ? props.edit.value : "")
    const [type, setType] = useState(props.edit ? props.edit.type : "")
    const [triggerType, setTriggerType] = useState(
        props.edit.trigger_type ? props.edit.trigger_type : ""
    )

    const [interval, setInterval] = useState(
        props.edit ? props.edit.interval : 0
    )
    const [minutes, setMinutes] = useState(0)
    const [hours, setHours] = useState(0)
    const [days, setDays] = useState(0)
    const [emails, setEmails] = useState(props.edit ? props.edit.email_action_detail?.emails : "")
    const [expressions, setExpressions] = useState([])

    const [alarms, setAlarms] = useState(props.edit ? props.edit.enable_alarm_actions_details.map(i=>({...i, name: i.alarm_name, id: i.alarm_id, contentTypeId: i.alarm_content_type})) : [])
    const allAlarms = useAllAlarms(props.unit)
    const useContentType = useAlarmContentType()

    const useAlarmRequest = useAlarmEmails()

    useEffect(() => {
        setInterval(minutes + hours + days)
    }, [minutes, hours, days])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (interval === 0) {
            dispatch(
                setAlert({severity: "error", message: "Interval is not set!"})
            )
            return
        }
        useAlarmRequest.mutateAsync({
            alarmType: ALARM_TYPES.analogintervalalarm,
            alarmData: {
                type,
                trigger_type: triggerType,
                name,
                value,
                expression: expression.id,
                interval,
            },
            emailActionData: { emails },
            edit: props.edit,
            enableAlarms: alarms.map(alarm => ({
                ...alarm,
                contentTypeId: alarm.alarm_content_type || useContentType.data.data[alarm.alarmType.type]
            })),
            actionType: actionType
        })
            .then(()=>{
                if (props.edit) {
                    dispatch(
                        setAlert({
                            status: "success",
                            message: "Alarm was edited.",
                        })
                    )
                } else {
                    dispatch(
                        setAlert({
                            status: "success",
                            message: "Alarm was created.",
                        })
                    )
                }
                props.handleClose()

            })
            .catch(()=>{
                dispatch(
                    setAlert({
                        severity: "error",
                        message: "Something went wrong.",
                    })
                )
            })
            .finally(()=>{
                props.handleClose()
            })
    }

    const expressionsRequest = useExpressions(props.unit, EXPRESSION.ANALOG)

    useEffect(()=>{
        if(expressionsRequest.isSuccess){
            setExpressions(expressionsRequest.data.data.results)
        }
        // eslint-disable-next-line
    },[expressionsRequest.data])

    useEffect(() => {
        setInterval(props.edit ? props.edit.interval : 0)
        setMinutes(interval % 60)
        setHours(Math.floor((interval % 1440) / 60) * 60)
        setDays(Math.floor(interval / 1440) * 1440)
        // eslint-disable-next-line
    }, [])

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {(props.edit ? "Edit " : "Add ") + " analog interval alarm"}
            </DialogTitle>
            <DialogContent dividers={true}>
                <form
                    className={classes.root}
                    id="add_expression_form"
                    onSubmit={handleSubmit}
                >
                    <TextField
                        label="Name:"
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        className={classes.formControl}
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />

                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Expression:</InputLabel>
                        <Select
                            onChange={(e) => setExpression(e.target.value)}
                            renderValue={(selected) => selected.name}
                            value={expression}
                        >
                            {expressions.map((item) => (
                                <MenuItem value={item}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Type</InputLabel>
                        <Select
                            onChange={(e) => {
                                setType(e.target.value)
                            }}
                            value={type}
                            renderValue={(selected) => renameType(selected)}
                        >
                            {Object.entries(alarmType).map(([key, item]) => {
                                return (
                                    <MenuItem key={key} value={key}>
                                        {item}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Trigger type</InputLabel>
                        <Select
                            onChange={(e) => {
                                setTriggerType(e.target.value)
                            }}
                            value={triggerType}
                            renderValue={(selected) => renameType(selected)}
                        >
                            {Object.entries(alarmTriggerType).map(
                                ([key, item]) => {
                                    return (
                                        <MenuItem key={key} value={key}>
                                            {item}
                                        </MenuItem>
                                    )
                                }
                            )}
                        </Select>
                    </FormControl>

                    <TextField
                        label="Value:"
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        className={classes.formControl}
                        onChange={(e) => setValue(e.target.value)}
                        helperText={"Must be numeric value!"}
                        value={value}
                    />

                    <Typography id="discrete-slider-always" gutterBottom>
                        Alarm interval:
                        <Box fontWeight="fontWeightBold" display="inline">
                            {secondsToString(interval)}
                        </Box>
                    </Typography>
                    <div className={classes.interval}>
                        <Slider
                            className={{padding: 10}}
                            getAriaValueText={(e) => `${e}min`}
                            aria-labelledby="discrete-slider-always"
                            step={1}
                            marks={[0, 5, 30, 60].map((item) => ({
                                value: item,
                                label: secondsToString(item),
                            }))}
                            valueLabelDisplay="auto"
                            min={0}
                            max={60}
                            value={interval % 60}
                            onChange={(event, newValue) => {
                                setMinutes(newValue)
                            }}
                            ValueLabelComponent={ValueLabelComponent}
                            valueLabelFormat={(x) => {
                                return secondsToString(x)
                            }}
                        />
                    </div>
                    <div className={classes.interval}>
                        <Slider
                            className={{padding: 10}}
                            getAriaValueText={(e) => `${e}min`}
                            aria-labelledby="discrete-slider-always"
                            step={60}
                            marks={[0, 720, 1440].map((item) => ({
                                value: item,
                                label: secondsToString(item),
                            }))}
                            valueLabelDisplay="auto"
                            min={0}
                            max={1440}
                            value={Math.floor((interval % 1440) / 60) * 60}
                            onChange={(event, newValue) => {
                                setHours(newValue)
                            }}
                            ValueLabelComponent={ValueLabelComponent}
                            valueLabelFormat={(x) => {
                                return secondsToString(x)
                            }}
                        />
                    </div>
                    <div className={classes.interval}>
                        <Slider
                            className={{padding: 10}}
                            getAriaValueText={(e) => `${e}min`}
                            aria-labelledby="discrete-slider-always"
                            step={1440}
                            marks={[1440, 20160].map((item) => ({
                                value: item,
                                label: secondsToString(item),
                            }))}
                            valueLabelDisplay="auto"
                            min={0}
                            max={20160}
                            value={Math.floor(interval / 1440) * 1440}
                            onChange={(event, newValue) => {
                                setDays(newValue)
                            }}
                            ValueLabelComponent={ValueLabelComponent}
                            valueLabelFormat={(x) => {
                                return secondsToString(x)
                            }}
                        />
                    </div>
                    {
                        actionType === ACTION_TYPE.EMA &&
                        <TextField
                            label="Notify:"
                            placeholder={"info@gmail.com, office@test.com, ..."}
                            variant="standard"
                            fullWidth
                            size="small"
                            className={classes.formControl}
                            onChange={(e) => setEmails(e.target.value)}
                            value={emails}
                        />
                    }
                    { actionType === ACTION_TYPE.ENA &&
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel id="demo-mutiple-chip-label">Reactivate alarms</InputLabel>
                            <Select
                                labelId="demo-mutiple-chip-label"
                                id="demo-mutiple-chip"
                                multiple
                                value={alarms}
                                onChange={
                                    (e)=>{
                                        let lastItem = e.target.value[e.target.value.length-1]
                                        let wasInAlarms = alarms.find(i=>i.name === lastItem.name)
                                        if (wasInAlarms){
                                            setAlarms(alarms => alarms.filter(i => i.name !== lastItem.name))
                                        }
                                        else{
                                            setAlarms(e.target.value)
                                        }

                                    }
                                }
                                input={<Input id="select-multiple-chip" />}
                                renderValue={(selected) => {
                                    return selected.map(i=> i.name).join(", ")
                                }}
                            >
                                {
                                    allAlarms.every(i=>i.isSuccess) && listAlarms(allAlarms, alarms)
                                }
                            </Select>
                        </FormControl>
                    }
                </form>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    justify="flex-end"
                    spacing={2}
                    className={classes.grid}
                >
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={props.handleClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        {useAlarmRequest.isLoading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                type="submit"
                                form="add_expression_form"
                                variant="contained"
                                color="primary"
                            >
                                {(props.edit ? "Edit " : "Add ") +
                                    " analog interval alarm"}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
